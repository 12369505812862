<style scoped></style>

<template>
  <Form label-position="top" ref="form" :model="formData" :rules="rules">
    <FormItem label="名称" prop="name">
      <Input v-model="formData.name" placeholder="请输入名称" />
    </FormItem>
    <FormItem label="编码" prop="code">
      <Input v-model="formData.code" placeholder="请输入编码" />
    </FormItem>
    <FormItem label="类型" prop="useType">
      <Select v-model="formData.useType" placeholder="请选择类型">
        <Option v-for="item in scmGoodsUseTypeList" :key="item.value" :value="item.value">{{ item.label }}</Option>
      </Select>
    </FormItem>
    <FormItem label="规格型号" prop="ggxh">
      <Input v-model="formData.ggxh" placeholder="请输入规格型号" />
    </FormItem>
    <FormItem label="体积(L)" prop="size">
      <InputNumber controls-outside v-model="formData.size" />
    </FormItem>
    <FormItem label="重量(kg)" prop="weight">
      <InputNumber controls-outside v-model="formData.weight" />
    </FormItem>
    <FormItem label="单位" prop="unit">
      <Select v-model="formData.unit" placeholder="请选择单位">
        <Option v-for="(item, index) in scmGoodsUnitList" :key="index" :value="item">{{ item }}</Option>
      </Select>
    </FormItem>
    <!-- <FormItem label="保质期(月)" prop="sellByDate">
      <InputNumber controls-outside v-model="formData.sellByDate" />
    </FormItem>
    <FormItem label="是否一物一码" prop="oneCode">
      <i-switch v-model="formData.oneCode">
        <template slot="open">
          <span>是</span>
        </template>
        <template slot="close">
          <span>否</span>
        </template>
      </i-switch>
    </FormItem>
    <FormItem label="是否区分生产批次" prop="needProduceBatch">
      <i-switch v-model="formData.needProduceBatch">
        <template slot="open">
          <span>是</span>
        </template>
        <template slot="close">
          <span>否</span>
        </template>
      </i-switch>
    </FormItem> -->
    <FormItem label="是否为整数量" prop="intVolume">
      <i-switch v-model="formData.intVolume">
        <template slot="open">
          <span>是</span>
        </template>
        <template slot="close">
          <span>否</span>
        </template>
      </i-switch>
    </FormItem>
    <FormItem label="状态" prop="status">
      <RadioGroup v-model="formData.status">
        <Radio v-for="item in scmGoodsStatusList" :label="item.value" :key="item.value" border>{{ item.label }}</Radio>
      </RadioGroup>
    </FormItem>
    <FormItem label="备注" prop="remark">
      <Input type="textarea" v-model="formData.remark" placeholder="请输入备注" />
    </FormItem>
  </Form>
</template>

<script>
import { getDefault } from './lib'

const rules = Object.freeze({
  name: [
    { required: true, message: '请输入名称' }
  ],
  useType: [
    { required: true, message: '请选择类型' }
  ]
})

export default {
  props: {
    data: { type: Object, default: null },
    useType: { type: Number, default: 1 }
  },
  data () {
    return {
      formData: this.data || getDefault(this.useType),
      rules
    }
  },
  computed: {
    scmGoodsUseTypeList () {
      return this.$store.getters.scmGoodsUseTypeList
    },
    scmGoodsUnitList () {
      return this.$store.getters.scmGoodsUnitList
    },
    scmGoodsStatusList () {
      return this.$store.getters.scmGoodsStatusList
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.formData = this.data ? JSON.parse(JSON.stringify(this.data)) : getDefault(this.useType)
      }
    }
  },
  methods: {
    getFormData () {
      return new Promise(resolve => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve(JSON.parse(JSON.stringify(this.formData)))
          } else {
            resolve(false)
          }
        })
      })
    }
  }
}
</script>
