<style lang="less" scoped>
  .cover {
    height: 50px;
  }
</style>

<template>
  <Tag class="click noborder" v-if="data" @click.native="show">附件</Tag>
</template>

<script>
import FileManage from '@/components/base/FileManage'
import request from '../../../api'
export default {
  props: {
    data: { type: Object, default: null }
  },
  data () {
    return {
      fileIds: this.data ? (this.data.fileIds || '').split(',').filter(v => v).map(v => Number(v)) : []
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.fileIds = this.data ? (this.data.fileIds || '').split(',').filter(v => v).map(v => Number(v)) : []
      }
    }
  },
  methods: {
    show () {
      this.$Modal.info({
        title: '附件管理',
        okText: '关闭',
        width: window.innerWidth / 2,
        render: (h) => {
          return h(FileManage, {
            props: {
              funs: { get: true, upload: true, del: true },
              preview: true,
              fileDataIds: this.fileIds
            },
            on: {
              addFile: this.addFile,
              delFile: this.delFile
            }
          })
        }
      })
    },
    addFile (file) {
      this.fileIds.push(file.id)
      this.update()
    },
    delFile (file) {
      const index = this.fileIds.findIndex(v => v === file.id)
      if (index > -1) {
        this.fileIds.splice(index, 1)
        this.update()
      }
    },
    async update () {
      const data = {
        ...this.data,
      }
      data.fileIds = this.fileIds.filter(v => v).join(',') || null
      await request.put('/goods/' + this.data.id, data)
      this.$Message.success('操作成功')
    }
  }
}
</script>
