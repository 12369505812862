export function getDefault (useType) {
  return {
    id: undefined,
    name: null,
    code: null,
    useType: useType || null,
    ggxh: null,
    size: null,
    weight: null,
    unit: '台',
    sellByDate: null,
    oneCode: false,
    needProduceBatch: false,
    intVolume: false,
    status: 1,
    remark: null
  }
}