<style scoped>
</style>

<template>
  <AppPage @on-rows-change="onRowsChange" :page="pager.page" :limit="pager.limit" :total="pager.total" @on-page-change="onPageChange">
    <template v-slot:header>
      <Row type="flex" justify="space-between" align="middle" style="margin-bottom: 10px;">
        <i-col>
          <Row type="flex" :wrap="false" :gutter="10" align="middle">
            <i-col>类型：</i-col>
            <i-col style="min-width: 120px">
              <Dropdown @on-click="useType => onSearch({ useType })">
                <template v-for="item in scmGoodsUseTypeList">
                  <Tag v-if="query.useType === String(item.value)" :key="item.value" size="large" style="cursor: pointer;">{{ item.label }}<Icon type="ios-arrow-down"></Icon></Tag>
                </template>
                <Tag size="large" v-if="query.useType === 'all'" style="cursor: pointer;" color="default">全部<Icon type="ios-arrow-down"></Icon></Tag>
                <DropdownMenu slot="list">
                  <DropdownItem :selected="query.useType === 'all'" name="all">全部</DropdownItem>
                  <DropdownItem v-for="item in scmGoodsUseTypeList" :key="item.value" :selected="query.useType === item.value" :name="String(item.value)">{{ item.label }}</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </i-col>
            <i-col>
              <i-input placeholder="请输入搜索内容" search @on-blur="onSearch()" @on-search="onSearch()" @on-clear="onSearch()" clearable v-model="query.value">
                <Select slot="prepend" style="width: 100px" v-model="query.key">
                  <Option value="name">名称</Option>
                  <Option value="code">编码</Option>
                </Select>
              </i-input>
            </i-col>
            <i-col>状态：</i-col>
            <i-col>
              <Dropdown @on-click="status => onSearch({ status })">
                <template v-for="item in scmGoodsStatusList">
                  <Tag v-if="query.status === String(item.value)" :key="item.value" size="large" style="cursor: pointer;" :color="item.color">{{ item.label }}<Icon type="ios-arrow-down"></Icon></Tag>
                </template>
                <Tag size="large" v-if="query.status === 'all'" style="cursor: pointer;" color="default">全部<Icon type="ios-arrow-down"></Icon></Tag>
                <DropdownMenu slot="list">
                  <DropdownItem :selected="query.status === 'all'" name="all">全部</DropdownItem>
                  <DropdownItem v-for="item in scmGoodsStatusList" :key="item.value" :selected="query.status === item.value" :name="String(item.value)">{{ item.label }}</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </i-col>
          </Row>
        </i-col>
        <i-col>
          <Button @click="refresh" icon="md-refresh">刷新</Button>
          <Button style="margin-left: 10px;" v-if="$authFunsProxy.add" @click="addClick" icon="md-add" type="primary">新增</Button>
        </i-col>
      </Row>
    </template>
    <template v-slot="{ contentHeight }">
      <Table :height="contentHeight" :data="dataList" :columns="columns" stripe :loading="loading.load">
        <template v-slot:oneCode="{ row }">
          <Tag class="noborder" :color="row.oneCode ? 'blue' : 'default'">{{ row.oneCode ? '是' : '否' }}</Tag>
        </template>
        <template v-slot:fileIds="{ row }">
          <GoodsFiles :data="row" />
        </template>
        <template v-slot:needProduceBatch="{ row }">
          <Tag class="noborder" :color="row.needProduceBatch ? 'blue' : 'default'">{{ row.needProduceBatch ? '是' : '否' }}</Tag>
        </template>
        <template v-slot:intVolume="{ row }">
          <Tag class="noborder" :color="row.intVolume ? 'blue' : 'default'">{{ row.intVolume ? '是' : '否' }}</Tag>
        </template>
        <template v-slot:status="{ row }">
          <Tag class="noborder" v-if="row.statusData" :color="row.statusData.color">{{ row.statusData.label }}</Tag>
        </template>
        <template v-slot:action="{ row }">
          <!-- <Button @click="itemStandard(row)" size="small" type="text">生产标准</Button> -->
          <Button style="margin-left: 10px" v-if="$authFunsProxy.update" @click="itemUpdate(row)" size="small" type="text">修改</Button>
          <Poptip style="margin-left: 10px" v-if="$authFunsProxy.del" transfer title="确定删除吗?" confirm @on-ok="itemDel(row)">
            <Button size="small" :loading="loading.del" type="text">删除</Button>
          </Poptip>
        </template>
      </Table>
    </template>
    <template v-slot:footer>
      <Drawer placement="right" :title="formData.id ? '修改' : '新增'" :mask-closable="false" closable transfer draggable :width="30" v-model="status.form">
        <DrawerAction :loading="loading.submit" @confirm="submit" @cancel="status.form = false">
          <GoodsForm :data="formData" ref="form"/>
        </DrawerAction>
      </Drawer>
    </template>
  </AppPage>
</template>

<script>
import AppPage from '../../../components/page/base.vue'
import DrawerAction from '../../../components/drawer/action.vue'
import request from '../../../api/index'
import { getDefault } from '../../../components/goods/base/lib'
import GoodsForm from '../../../components/goods/base/form.vue'
import GoodsFiles from '../../../components/goods/base/files.vue'

export default {
  components: { AppPage, GoodsForm, GoodsFiles, DrawerAction },
  data () {
    return {
      pager: {
        page: 1,
        limit: 10,
        total: 0
      },
      query: {
        key: 'name',
        value: null,
        status: 'all',
        useType: 'all'
      },
      loading: {
        load: false,
        submit: false,
        del: false
      },
      dataList: [],
      status: {
        form: false
      },
      formData: getDefault(null)
    }
  },
  computed: {
    scmGoodsUseTypeList () {
      return this.$store.getters.scmGoodsUseTypeList
    },
    scmGoodsStatusList () {
      return this.$store.getters.scmGoodsStatusList
    },
    columns () {
      let data = [
        { title: '类型', key: 'useTypeLabel' , fixed: 'left', width: 100},
        { title: '名称', key: 'name' , fixed: 'left', width: 170},
        { title: '编码', key: 'code' , fixed: 'left', width: 110},
        { title: '规格型号', key: 'ggxh' , fixed: 'left', width: 170},
        { title: '状态', key: 'status', slot: 'status', minWidth: 80 },
        { title: '单位', key: 'unit' , minWidth: 70},
        { title: '体积', key: 'size', minWidth: 70 },
        { title: '重量', key: 'weight', minWidth: 70 },
        // { title: '保质期', key: 'sellByDate' },
        // { title: '是否一物一码', key: 'oneCode', slot: 'oneCode' },
        // { title: '是否区分生产批次', key: 'needProduceBatch', slot: 'needProduceBatch' },
        { title: '整数量', key: 'intVolume', slot: 'intVolume', minWidth: 80},
        { title: '备注', key: 'remark', minWidth: 200 },
        { title: '附件', key: 'fileIds', minWidth: 80, slot: 'fileIds', fixed: 'right' },
        { title: '操作', key: 'action', slot: 'action', width: 160 , fixed: 'right'},
      ]
      if (!this.$authFunsProxy.fileData) {
        data = data.filter(v => v.key !== 'fileIds')
      }
      return data
    }
  },
  methods: {
    async submit () {
      try {
        const data = await this.$refs.form.getFormData()
        if (!data) {
          return this.$Message.warning('请完整填写表单')
        }
        this.loading.submit = true
        if (data.id) {
          await request.put('/goods/' + data.id, data)
        } else {
          await request.post('/goods', data)
        }
        this.$Message.success('操作成功')
        this.status.form = false
        if (data.id) {
          this.loadData()
        } else {
          this.refresh()
        }
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.submit = false
    },
    addClick () {
      this.formData = getDefault()
      this.status.form = true
    },
    itemUpdate (item) {
      this.formData = JSON.parse(JSON.stringify(item))
      this.status.form = true
    },
    async itemDel (item) {
      this.loading.del = true
      try {
        await request.delete('/goods/' + item.id)
        this.$Message.success('已删除')
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.del = false
    },
    onSearch (params) {
      if (params) {
        Object.assign(this.query, params)
      }
      this.pager.page = 1
      this.loadData()
    },
    refresh () {
      this.pager.page = 1
      this.query.key = 'name'
      this.query.value = null
      this.query.status = 'all'
      this.loadData()
    },
    async onPageChange (page) {
      this.pager.page = page
      this.loadData()
    },
    async loadData () {
      if (!this.$authFunsProxy.get) {
        return
      }
      const params = {}

      if (this.query.key && this.query.value) {
        params[this.query.key] = this.query.value
      }

      if (this.query.status !== 'all') {
        params.status = Number(this.query.status)
      }
      if (this.query.useType !== 'all') {
        params.useType = this.query.useType
      }

      this.loading.load = true
      try {
        const res = await request.get('/goods', Object.assign(params, {
          pageNum: this.pager.page,
          pageSize: this.pager.limit
        }))

        res.data.forEach(item => {
          item.statusData = this.scmGoodsStatusList.find(v => v.value === item.status)
          item.useTypeData = this.scmGoodsUseTypeList.find(v => v.value === item.useType)
          item.useTypeLabel = item.useTypeData ? item.useTypeData.label : ''
        })

        this.pager.total = res.total
        this.dataList = res.data
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.load = false
    },
    onRowsChange (rows) {
      this.pager.page = 1
      this.pager.limit = rows
      this.loadData()
    },
    itemStandard (row) {
      this.$router.push({
        name: 'scm.produce.standard',
        query: { goodsId: row.id }
      })
    }
  }
}
</script>
